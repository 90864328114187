import React, { useState, useEffect } from "react";
import {
  Card,
  Button,
  CardBody,
  CardGroup,
  CardTitle,
  CardText,
  CardSubtitle,
  Col,
} from "reactstrap";
import etherscanLogo from "../../assets/img/etherscan-logo.png";
import AdvantageFinal from "../../assets/pdf/AdvantageFinal.pdf";
import AnimalConcerts from "../../assets/pdf/AnimalConcertsFinalReport.pdf";
import Float from "../../assets/pdf/FloatProtocolReport.pdf";
import Gemini from "../../assets/pdf/GeminiFinal.pdf";
import Helix from "../../assets/pdf/Helix.pdf";
import PWNAudit from "../../assets/pdf/PWNAudit.pdf";
import PWNProject from "../../assets/pdf/ReviewReport.pdf";
import Smuggle from "../../assets/pdf/SmuggleFinal2.pdf";
import Almanac from "../../assets/pdf/AlmanacNFT.pdf";
import Pwn6 from "../../assets/pdf/PWN6.pdf";
import Social from "../../assets/pdf/Social.pdf";
import Comoco from "../../assets/pdf/Comoco.pdf";
import Whirl from "../../assets/pdf/Whirl.pdf";

// reports
import axios from "axios";

import defiAuditsSummary from "./defiAuditsSummary";

const Audits = () => {
  const [auditRepositoryURL, setAuditRepositoryURL] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [auditFiles, setAuditFiles] = useState([]);
  const [show, setShow] = useState(false);
  // const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let audits = [
    { name: "Advantage Final Audit", file: AdvantageFinal },
    { name: "Animal Concerts Audit", file: AnimalConcerts },
    { name: "Float protocolAudit", file: Float },
    { name: "Gemini Audit", file: Gemini },
    { name: "Helix Audit", file: Helix },
    { name: "PWN Audit", file: PWNAudit },
    { name: "PWN Project Audit", file: PWNProject },
    { name: "The Smuggleverse Audit", file: Smuggle },
    { name: "PWN Multitoken Audit", file: Pwn6 },
    //{ name: "Quant Network Audit", file: Quant },
    { name: "Comoco Audit", file: Comoco },
    { name: "Starknet Social", file: Social },
    { name: "Almanac NFT Audit", file: Almanac },
    { name: "Whirl Finance Audit", file: Whirl },
  ];

  // useEffect(() => {
  //   const fetchRepository = async () => {
  //     if (auditFiles.length === 0) {
  //       setLoading(true);
  //       const repoResponse = await axios.get(
  //         "https://api.github.com/repos/ExtropyIO/audits-test"
  //       );
  //       setAuditRepositoryURL(repoResponse.data.html_url);
  //       const response = await axios.get(
  //         "https://api.github.com/repos/ExtropyIO/audits-test/contents/pdfs"
  //       );
  //       // console.log(response);
  //       // console.log(pdfs);
  //       console.log(response.data);
  //       setAuditFiles();
  //       // setAuditFiles(pdfs);
  //       setLoading(false);
  //     }
  //   };
  //   fetchRepository();
  // for testing purpose
  // setLoading(true);
  // setAuditRepositoryURL("https://google.com");
  // setAuditFiles([{ filename: "alice.pdf" }, { filename: "bob.pdf" }, { filename: "carol.pdf" }]);
  // setLoading(false);
  // }, [auditFiles.length]);

  return (
    <div style={{ padding: "10% 5% 2.5% 5%" }}>
      <h1>Public Audits</h1>
      <p>
        Same of the recent audits thet we've been working on
        {/* <a href={auditRepositoryURL}>{auditRepositoryURL}</a> */}
      </p>
      <CardGroup>
        {!isLoading &&
          audits.map((file, index) => {
            return (
              <Col sm="4">
                <Card>
                  <CardBody>
                    <CardTitle tag="h5">{file.name}</CardTitle>

                    <CardText>
                      Click below to see pdf version of this audit
                    </CardText>
                    <a
                      href={file.file}
                      without
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <Button variant="primary" onClick={handleShow}>
                        {file.name}
                      </Button>
                    </a>

                    {/* 
                    <Modal show={show} onHide={handleClose}>
                      <Modal.Header closeButton>
                        <Modal.Title>Modal heading</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <PdfView pdf={testPdf} />
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal> */}
                  </CardBody>
                </Card>
              </Col>
            );
          })}{" "}
      </CardGroup>

      {/* <ListGroup style={{ margin: "2.5% 15%" }}>
        <ListGroupItem>
          <div className="row">
            <div className="col-md-8 d-flex align-items-center">Project Name</div>
            <div className="col-md-3 d-flex align-items-center">Link</div>
          </div>
        </ListGroupItem>
        {!isLoading &&
          auditFiles.map((file) => {
            return (
              <ListGroupItem key={file.name}>
                <div className="row">
                  <div className="col-md-8 d-flex align-items-center">
                    <FontAwesomeIcon className="px-2" size="1x" icon={faFilePdf} color="#AA3939" />
                    <span className="px-2">{file.name}</span>
                  </div>
                  <div className="col-md-3 d-flex align-items-center">
                    <FontAwesomeIcon icon={faLink} className="px-2" />
                    <a href={file.html_url} target="_blank" rel="noopener noreferrer">
                      view on Github
                    </a>
                  </div>
                </div>
              </ListGroupItem>
            );
          })}
      </ListGroup> */}
    </div>
  );
};

const DefiAudits = () => {
  const getIconColour = (faIcon) => {
    if (faIcon == "fa-check-circle") return "green";
    if (faIcon == "fa-question-circle") return "orange";
    if (faIcon == "fa-times-circle") return "red";
  };

  return (
    <div style={{ padding: "2.5% 5%" }}>
      <h1>Defi Landscape - Audits Summary</h1>
      <p>
        In the interests of making the Defi space more transparent for
        investors, we've put together a list of audit reports from different
        Defi firms.{" "}
      </p>
      <p>
        We've also included a list of the main points from each report, or any
        impartial comments regarding firms who may have not had an audit report.{" "}
      </p>
      <p>
        For security researchers and smart contract auditors, you will also find
        in this table a{" "}
        <strong>link to the audited contracts on Etherscan</strong> to further
        analysis of the smart contracts and Solidity code.
      </p>
      <p>
        <div class="alert alert-primary" role="alert">
          <h5>Disclaimer:</h5>
          <p>
            We strongly recommend that investors read through the reports and
            research each firm independently. Our main points are quotes from
            each report. A new audit report may have been carried out since we
            last updated the table, therefore the points may not reflect the
            present state of security.
          </p>
        </div>
      </p>
      <table class="table table-bordered">
        <tr style={{ color: "#fcfcfc", backgroundColor: "#333985" }}>
          <th class="text-center">
            <h5>Defi Firm</h5>
          </th>
          <th class="text-center">
            <h5>Audit Report</h5>
          </th>
          <th>
            <h5>Main points from report / comments</h5>
          </th>
          <th class="text-center">
            <h5>View report(s)</h5>
          </th>
          <th class="text-center">
            <h5>Smart Contract(s)</h5>
          </th>
        </tr>
        {defiAuditsSummary.map((report) => {
          return (
            <tr>
              <td class="text-center">
                <b>{report.defi_project_name}</b>
              </td>
              <td class="text-center" style={{ fontSize: "1.5rem" }}>
                {" "}
                <i
                  class={"fas " + report.icon}
                  style={{ color: getIconColour(report.icon) }}
                ></i>
              </td>
              <td>
                <details>
                  <summary>Click to expand</summary>
                  <u>auditor:</u>&nbsp;{report.auditor}
                  <p>{report.summary}</p>
                </details>
              </td>
              <td class="text-center">
                <a href={report.report_link} target="_blank">
                  <i
                    width={50}
                    class="mx-2 fas fa-file-pdf"
                    style={{ color: "#AA3939", fontSize: "1.5rem" }}
                  ></i>
                  click to audit report in pdf
                </a>
              </td>
              <td class="text-center">
                <a href={report.smart_contract_link} target="_blank">
                  <img class="mx-2" src={etherscanLogo} width={30} /> view on
                  Etherscan
                </a>
              </td>
            </tr>
          );
        })}
      </table>
    </div>
  );
};

const Main = () => {
  return (
    <>
      <Audits />
      <DefiAudits />
    </>
  );
};

export default Main;
